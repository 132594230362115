import { PlugsGridStyled } from './plugs-grid.styled';

export interface PlugsGridProps {
  children?: React.ReactNode;
  className?: string;
  gridSize?: boolean;
}

export const PlugsGrid: React.FC<PlugsGridProps> = ({
  children,
  gridSize,
  className,
}) => {
  return (
    <PlugsGridStyled $gridSize={gridSize} className={className}>
      {children}
    </PlugsGridStyled>
  );
};
