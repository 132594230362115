import styled from 'styled-components';

import { breakpoints } from '@hultafors/shared/constants';

interface StyledPlugsGridProps {
  $gridSize?: boolean;
}

export const PlugsGridStyled = styled.section<StyledPlugsGridProps>`
  max-width: 1440px;
  width: calc(100vw - 40px);
  margin-inline: auto;
  margin-block: 30px;
  display: grid;
  gap: 20px;
  grid-template-columns: 1fr;

  > * {
    aspect-ratio: ${({ $gridSize }) => ($gridSize ? '1 / 1' : '3 / 4')};
  }

  @media screen and (min-width: ${breakpoints.largeMobile}) {
    grid-template-columns: repeat(
      ${({ $gridSize }) => ($gridSize ? 1 : 2)},
      1fr
    );
  }

  @media screen and (min-width: ${breakpoints.desktop}) {
    margin-block: 4rem;
  }

  @media screen and (min-width: ${breakpoints.desktopMedium}) {
    grid-template-columns: repeat(
      ${({ $gridSize }) => ($gridSize ? 3 : 4)},
      1fr
    );
  }

  @media screen and (min-width: ${breakpoints.desktopLarge}) {
    gap: 30px;
  }
`;
